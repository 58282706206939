.signature-pad{
    width: 100% !important;
    height: 100% !important;
}

.signature-container{
    width: 100%;
    aspect-ratio: 2;
    background-color: white;
}

.signature-panel{
    width: 100%;
    height: 100%;
}