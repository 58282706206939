.tabBtn{
    background: transparent !important;
    font-size: large !important;
}

@media (prefers-color-scheme: dark) {
    .tabBtn:hover{
        color: white !important;
        border-bottom: #fff 1px solid !important;
        font-weight: 700 !important;
    }

    .tabBtn{
        color: #95b122 !important;
        
    }

    .tabBtn.active{
        border-bottom: #95b122 1px solid !important;
    }

    .optionsBtn{
        color: white;
    }

    .optionsBtn:hover{
        color:white;
    }

    .textBox:focus{
        border: 1px white solid !important;
        outline: #fff !important;
    }

    .modalWindow{
        background: #333;
        color:#fff;
    }
}

@media (prefers-color-scheme: light) {
    .tabBtn:hover{
        background: #000;
    }

    .modalWindow{
        background: #fff;
        color:#000;
    }

    .tabBtn{
        color: #95b122 !important;
        
    }
    .tabBtn.active{
        border-bottom: #95b122 2px solid !important;
    }

    .tabBtn:hover{
        border-bottom: #95b122 2px solid !important;
    }


}