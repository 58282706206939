.background{
    display: flex;
    width:100%;
    height:100%;
    opacity: 0.0;
    visibility:hidden;
    background:black;
    position:fixed;
    z-index:1005;
    transition: opacity .5s, visibility .5s;
}

.background.background-visible{
    visibility: visible;
    opacity: .5;
}


.sidebar{
    z-index:1006;
    height: 100%;
    
    position: fixed;
    visibility: hidden;
    transition: margin .5s, visibiliy .5s;
    margin:0 0 0 -4999px;
}

@media (orientation: landscape) {
    .sidebar{
        max-width: 40vh;
    }
  }

  @media (orientation: portrait) {
    .sidebar{
        max-width: 50vw;
    }
  }

@media (prefers-color-scheme: dark) {
    .sidebar{
        background: #333;
    }

    .menuHeader{
        color: #fff;
        border-radius: 0;
        font-size:medium;
    }

    .btn > a{
        color: #638100;
        font-size:medium;
    }

    .sidebar-btn{
        background: transparent;
        border-radius: 0;
    }
}

@media(prefers-color-scheme: light){
    .sidebar{
        background: white;
    }

    .menuHeader{
        color: #638100;
        font-size:medium;
    }

    .btn > a{
        color: #606060;
        font-size:medium;
    }
    
}

.sidebar.sidebar-visible{
    margin-left: 0;
    visibility: visible;
}

.header{
    margin-top: 58px;
    width: 100%;
    height: 50px;
}

.logo{
    width:100%;
    /* padding:1em */
}

.logoImage{
    width: inherit;
}

.menu{
    /* margin-top: 20px; */
    box-shadow: none;
    text-shadow: none;
}

.button-group{
    width: 100% !important;
    border-radius: 0;
    box-shadow: none;
}

.button-group:hover{
    box-shadow: none;
}
