.content{
    padding: 20px;
}

.dropodown-btn{
    background:#fff;
    border-radius: 0;
    border-bottom: 2px solid #95b122 !important;
    color: #000;
    width: 100%;
}

.dropodown-btn:hover{
    background: #95b122;
}

.dropodown-btn::after{
    color: #95b122;
}

.dropdown-item{
    width: 90vw;
    border-radius: 0;
}

p{
    font-weight: 700;
    color: #638100;
    margin-bottom: 0;
}

.spacer{
    margin-top: 20px;
}

.checkbox:checked{
    background: #95b122;
    border: 1px solid #000;
}

