.container {
  width: 100%;
  height: 100vh;
  top: 10%;
  left: 10%;
}

.sigContainer {
  width: 90%;
  /* aspect-ratio: 2; */
  margin: 20px auto;
  background-color: #fff;
  border: 1px solid black;
}

.sigPad {
  width:100%;
  aspect-ratio: 2;
  /* height: 50vh; */
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}

.sigBtns {
  margin: 10px auto;
  width: 90%;
}

.sigBtn {
  margin-left: 10px;
}

.sigNameInput {
  width: 30%;
  padding-bottom: 15px;
}
