.trashIcon{
    color: #95b122;
}

.trashIcon:hover{
    color: #fff;
}

.fileName{
    margin-left: 15px;
}