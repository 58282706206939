.mainDiv {
  padding: 10px;
}

.taskInfo {
  font-size: normal;
  margin-bottom: 10px;
}

.bold {
  font-weight: 700;
}

.iconAngle {
  color: green;
  font-size: large;
}

@media (prefers-color-scheme: light) {
  .infoSection > span {
    color: #000;
  }

  .infoSection > span > p {
    color: #000;
  }
}

@media (prefers-color-scheme: dark) {
  .infoSection > span {
    color: #fff;
  }

  .infoSection > span > p {
    color: #fff;
  }
}
