.color-primary{
    color: #95b122 !important;
}

.bg-primaryUtil{
    background: #95b122;
}

.color-primaryDark{
    color: #638100 !important;
}

.bg-primaryDark{
    background: #638100;
}

.navbar-primaryDark{
    background: #638100;
}

.bg-primaryDark:hover{
    background: #95b122;;
}

.color-onPrimary{
    color: #000;
}

.color-secondary{
    color: #2294b1;
}

.color-onSecondary{
    color: #000;
}

.color-indicator{
    color: #fff;
}

.color-indicatorText{
    color: #757575
}

.color-indicatorError{
    color: #A1262D
}

.color-indicatorErrorText{
    color: #FFF
}

.color-indicatorWarning{
    color: #E8BF28
}

.color-indicatorWarningText{
    color: #606060 !important;
}

.color-confirmationText{
    color: #000;
}

.color-confirmationFooter{
    color: #888;
}

.color-confirmation-Marker{
    color: #FFF;
}

.checkbox:checked{
    background: #638100 !important;
    border: 1px solid black;
}

body{
    overflow-x: hidden;
    overscroll-behavior-y: contain;
}

.hidden{
    display: none;
}


@media (prefers-color-scheme: light) {
    body {
      background: #FFF;
      color: #333;
    }
  }
  @media (prefers-color-scheme: dark) {
    body {
      background: #333;
      color: #FFF;
    }
  }