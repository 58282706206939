.form-check-input[type=checkbox]:checked{
    background-color: #95b122;
    color: #95b122;;
}

.form-check-input:checked{
    border-color: #95b122;
}

.form-switch .form-check-input:checked[type=checkbox]:after{
    background-color: #95b122;
}

.form-check-input[type=checkbox]:checked:focus{
    background-color: #95b122;
}