

.stateContainer{
    width: 100%;
    height: 100%;
}

.normalText{
    font-size: normal;
    display:block;
    line-height: 1.5em;
}

.address{
    font-size:normal;
    display:block;
    font-weight: 700;
    line-height: 1.5em;
}

@media (prefers-color-scheme: light) {
    .normalRow{
        color: #4b515d;
        height: 100%;
    }

    .warningRow{
        color: #000;
        background: #E8BF28;
        height: 100%;
    }

    .errorRow{
        color: #fff;
        background: #A1262D;
    }

    .taskTypeIcon{
        width: 20px;
    }

    .taskTypeIconFill{
        fill: #000;
    }
  }

  @media (prefers-color-scheme: dark) {
    .normalRow{
        color: #fff;
        height: 100%;
    }

    .warningRow{
        color: #000;
        background: #E8BF28;
        height: 100%;
    }

    .errorRow{
        color: #fff;
        background: #A1262D;
    }

    .taskTypeIcon{
        width: 20px;
    }

    .taskTypeIconFill{
        fill: #fff;
    }
}
