.cameraContainer{
    width: 100%;
    /* height: 100vh; */
    top: 10%;
    left: 10%;
}

.cameraBox{
    width:90%;
    margin: 10px auto;
}

.camera{
    width: 100%;
}

@media (prefers-color-scheme: dark) {
    .modalWindow{
        background: #333;
        color:#fff;
    }
}

@media (prefers-color-scheme: light) {
    .modalWindow{
        background: #fff;
        color:#000;
    }
}