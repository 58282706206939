.navbar{
    width: 100%
}

.navbar-iconLink{
    cursor: pointer;
}

.navbar-iconLink:hover{
    color: #fff;
}

.navbar-hidden{
    display: none;
}

