@media (prefers-color-scheme: dark) {
    .modalWindow{
        background: #333;
        color:#fff;
    }

    .closeIcon{
        color:white !important;
    }
}

@media (prefers-color-scheme: light) {
    .modalWindow{
        background: #fff;
        color:#000;
    }

    .closeIcon{
        color: black !important;
    }
}